import React from "react"

import {graphql, useStaticQuery} from 'gatsby'

import Layout from '../containers/layout'

import Container from '../components/container'
import MetaSchemaSEO from '../components/seo';


export const query = graphql`
  query {
    strapiSiteSetting {
      id
      site_name
      description: default_seo_description
    }
    site {
      siteMetadata {
      siteUrl
      }
    }
  }
`


// markup
const DisclosurePage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout>
    <MetaSchemaSEO
      title = "Disclosure"
    />
      <Container>
        <h1>Affiliate Disclosure</h1>

        <p>We are an affiliate of many of the brands and products that we review and promote on this website. What this means is that if you purchase a product by clicking on a link or image on this website, we may earn a small affiliate commission. (This is like a referral fee).</p>

        <p>To be 100% clear, you should assume that we will earn a commission on any product that you purchase after clicking on links or images on this website.</p>

        <p>Our affiliate partners include but are not limited to Amazon.com, Walmart.com, and Ebay.com.</p>

        <p>We use these referral fees to pay hosting costs and to further improve this site, so that we may provide the best service to you and your fellow readers.</p>

        <p>Although we only recommend products that we feel are of the best quality (and that we may or may not have personal experience with) and represent value for money, you should be aware that our opinions can differ. A product that that we really like and recommend may not be suitable at all for your own unique goals and aspirations. So always be sure to do your own due diligence on any product before you purchase it.</p>

        <p>{data.strapiSiteSetting.site_name} and associated websites are participants in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to amazon.com, amazon.ca, amazon.uk, amazon.de, amazonsupply.com, or myhabit.com.</p>
      </Container>
    </Layout>
  )
}

export default DisclosurePage
